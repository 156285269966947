<template>
  <PageWithLayout
    class="page_detail"
    :isBodyFull="true"
    :topPadding="16"
    :bottomPadding="74"
    :isBackBtn="true"
    :isBackWebEnd="
      $route.query.isBackWebEnd && $route.query.isBackWebEnd === 'true'
    "
    :backRouteName="viewModel.closeRouteName"
    :backRouteQuery="$route.query"
    :backPage="$route.query.backPage"
  >
    <div class="head_detail">
      <div class="area_overflow">
        <div
          class="thumb_item"
          :style="{
            'background-image': `url(${viewModel.transDetailResponse?.profile_image_url})`,
            'background-color': viewModel.transDetailResponse?.profile_image_url
              ? 'none'
              : '#F3F1F1',
          }"
        ></div>
        <strong class="tit_trans">{{
          viewModel.transDetailResponse?.transaction_name
        }}</strong>
      </div>
      <span class="price_trans"
        >{{
          viewModel.transDetailResponse?.transaction_amount
            | convertNumberToComma(false, '0')
        }}<span class="txt_price_unit">원</span></span
      >
    </div>

    <div class="list_detail_info">
      <!-- 카테고리 -->
      <dl
        v-if="
          viewModel.transDetailResponse?.payment_category &&
            viewModel.transDetailResponse?.payment_category_name
        "
      >
        <!-- 부모가 보는 자녀상세 -->
        <template v-if="viewModel.isParentsChild">
          <dt>카테고리</dt>
          <dd>
            <span
              class="inner_dd"
              v-html="
                viewModel.transDetailResponse?.payment_category_name || ''
              "
            ></span>
          </dd>
        </template>
        <!-- 자녀본인 상세 -->
        <template v-else>
          <button
            class="btn_edit"
            :disabled="viewModel.isLoading"
            @click="viewModel.onClickCategoryLayerOpen()"
          >
            <dt>카테고리</dt>
            <dd>
              <IconSvg iconName="arrow_right" :size="24" iconColor="#A6A4A4" />
              <span
                class="inner_dd primary"
                v-html="
                  viewModel.transDetailResponse?.payment_category_name || ''
                "
              ></span>
            </dd>
          </button>
        </template>
      </dl>
      <!-- 소비기록 -->
      <!-- 지출만 소비기록 노출 -->
      <dl
        v-if="
          viewModel.userType != 'PARENTS' &&
            viewModel.transDetailResponse?.transaction_category === 'PAYMENT'
        "
      >
        <!-- 부모가 보는 자녀상세 -->
        <template v-if="viewModel.isParentsChild">
          <dt v-html="$poData.trans_details_heading_pay_log"></dt>
          <dd>
            <span class="txt_placeholder">{{
              !hasPaymentImageLog
                ? $poData.trans_details_pay_log_form_child_only
                : ''
            }}</span>
          </dd>
        </template>
        <!-- 자녀본인 상세 -->
        <template v-else>
          <button class="btn_edit" @click="payLogEditor()">
            <dt v-html="$poData.trans_details_heading_pay_log"></dt>
            <dd>
              <IconSvg iconName="arrow_right" :size="24" iconColor="#A6A4A4" />
              <span
                v-if="!hasPaymentImageLog"
                class="txt_placeholder"
                v-html="$poData.trans_details_pay_log_form_placeholder_main"
              ></span>
            </dd>
          </button>
        </template>
        <!-- 사진 영역 -->
        <dd :class="['dd_image', { dd_has_image: hasPaymentImageLog }]">
          <p
            v-if="!hasPaymentImageLog"
            class="desc_placeholder"
            v-html="$poData.trans_details_pay_log_form_placeholder_sub"
          ></p>
          <template v-else>
            <div
              class="area_image"
              :style="{
                border: `4px solid ${viewModel.transDetailResponse?.feedback_category_response.rgb_code}`,
                'background-image': `url(${viewModel.transDetailResponse?.payment_image_log_response.image_link})`,
              }"
            ></div>
            <div class="area_feedbak_category">
              <div
                class="img_text"
                :style="{
                  'background-image': `url(${viewModel.transDetailResponse?.feedback_category_response.feedback_text_link})`,
                }"
              ></div>
              <div
                class="img_character"
                :style="{
                  'background-image': `url(${viewModel.transDetailResponse?.feedback_category_response.character_image_link})`,
                }"
              ></div>
            </div>
          </template>
        </dd>
      </dl>
      <!-- 메모 -->
      <dl>
        <!-- 부모가 보는 자녀상세 -->
        <template v-if="viewModel.isParentsChild">
          <dt v-html="$poData.trans_details_heading_memo"></dt>
          <dd>
            <span
              v-if="viewModel.transDetailResponse?.memo"
              class="inner_dd"
              v-html="viewModel.transDetailResponse?.memo"
            ></span>
            <span
              v-else
              class="txt_placeholder"
              v-html="$poData.trans_details_memo_form_child_only"
            ></span>
          </dd>
        </template>
        <!-- 자녀본인 상세 -->
        <template v-else>
          <button class="btn_edit" @click="viewModel.goMemo()">
            <dt v-html="$poData.trans_details_heading_memo"></dt>
            <dd>
              <IconSvg iconName="arrow_right" :size="24" iconColor="#A6A4A4" />
              <span
                v-if="viewModel.transDetailResponse?.memo"
                class="inner_dd"
                v-html="viewModel.transDetailResponse?.memo"
              ></span>
              <span
                v-else
                class="txt_placeholder"
                v-html="$poData.trans_details_memo_form_placeholder"
              ></span>
            </dd>
          </button>
        </template>
      </dl>
    </div>
    <div class="list_detail_info">
      <dl
        v-for="(infoData, index) in viewModel.displayList"
        :key="`infoItem${index}`"
      >
        <dt :class="`tc_${infoData.key.color_name.toLowerCase()}`">
          {{ infoData.key.text }}
        </dt>
        <dd  v-if="!getIsBarcode(infoData.key.text)" :class="`tc_${infoData.value.color_name.toLowerCase()}`">
          {{ infoData.value.text }}
        </dd>
        <!-- 결제수단 바코드 -->
        <dd v-else :class="`tc_${infoData.value.color_name.toLowerCase()}`">
          <template v-if="viewModel.isParentsChild">바코드</template>
          <button v-else type="button" class="btn_barcode" 
            @click="
              sendInterface('barcodeShow', {
                barcode_number: infoData.value.text.replace('바코드 ',''),
              })
            ">
            <div class="icon_exclam">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <circle cx="9.99996" cy="9.99984" r="8.33333" fill="white"/>
                <path d="M9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM10.8333 14.1665H9.16663V12.4998H10.8333V14.1665ZM10.8333 10.8332H9.16663V5.83317H10.8333V10.8332Z" fill="#A6A4A4"/>
              </svg>
            </div>
            <span class="txt_value_barcode">바코드</span>
          </button>
        </dd>
      </dl>
    </div>
    <template v-slot:popup>
      <Layer
        v-if="viewModel.isShowPopopCategory"
        :title="$poData.change_category_optionsheet_title"
        @onClickClose="viewModel.onClickCategoryLayerClose()"
      >
        <CategorySelectLayerContent
          :datas="viewModel.transDetailResponse?.payment_category_list || []"
          :prevValue="viewModel.transDetailResponse?.payment_category"
          @onClickItem="data => viewModel.onClickCategoryItem(data)"
        />
      </Layer>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import IconSvg from '@/components/common/icon/IconSvg';

// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
// viewModel
import TransDetailViewModel from '@/views/v2/transaction/viewModel/TransDetailViewModel';

import Layer from '@/components/layout/popup/Layer';
import CategorySelectLayerContent from '@/components/layout/popup/categorySelectLayerContent/CategorySelectLayerContent';

export default {
  name: 'TransDetail',
  components: {
    PageWithLayout,
    IconSvg,
    Layer,
    CategorySelectLayerContent,
  },
  mixins: [InterFaceMixin],
  data() {
    return {
      viewModel: new TransDetailViewModel(
        this.$route.params.id,
        this.$route.query,
      ),
    };
  },
  computed: {
    hasPaymentImageLog() {
      return this.viewModel.transDetailResponse?.payment_image_log_response;
    },
  },
  methods:{
    getIsBarcode(keyName){
      const isBarcodePayment = this.viewModel.transDetailResponse?.payment_resource === 'BARCODE';
      const isPaymentDl = keyName === '결제 수단';
      return isBarcodePayment && isPaymentDl;
    },
    payLogEditor(){
      const andInterfaceFunction = () => {
        this.sendInterface('payLogEditor', {
          transactionUid: this.viewModel.transactionUid,
        });
      };
      const iosInterfaceFunction = () => {
        this.pageMove('payLogEditor', {
          transactionUid: this.viewModel.transactionUid,
          transaction_name: this.viewModel.transDetailResponse?.transaction_name,
          transaction_amount: this.viewModel.transDetailResponse?.transaction_amount,
          payment_image_log_response: this.viewModel.transDetailResponse?.payment_image_log_response,
          feedback_category_response: this.viewModel.transDetailResponse?.feedback_category_response,
        })
      };
      this.customSendInterface(andInterfaceFunction, iosInterfaceFunction);
    }

    
  }
};
</script>

<style scoped>
/* overflow:hidden;min-height:100%; */
.page_detail .head_detail {
  padding: 0 20px;
}
.page_detail .head_detail + .list_detail_info {
  margin-top: 8px;
}
/* .page_detail >>> .body_drop {
  position: relative;
  padding-bottom: 104px;
} */
.page_detail .thumb_item {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-size: 32px;
}
.page_detail .tit_trans {
  overflow: hidden;
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  color: #666;
}
.page_detail .price_trans {
  display: block;
  margin-top: 4px;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #111;
}
.page_detail .price_trans .txt_price_unit {
  display: inline-block;
  vertical-align: top;
}
.page_detail .list_detail_info {
  padding: 16px 20px;
}
.page_detail .list_detail_info + .list_detail_info {
  border-top: 12px solid #f3f1f1;
}
.page_detail .list_detail_info dl {
  display: block;
  overflow: hidden;
}
.page_detail .list_detail_info dl + dl {
  margin-top: 16px;
}

.page_detail .list_detail_info .btn_edit {
  display: block;
  width: 100%;
  overflow: hidden;
}
.page_detail .list_detail_info .btn_edit .txt_placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8f69dd;
}

.page_detail .list_detail_info dt,
.page_detail .list_detail_info dd {
  padding: 8px 0;
}
.page_detail .list_detail_info dt {
  float: left;
  width: 120px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
.page_detail .list_detail_info dd {
  overflow: hidden;
  min-height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}

.page_detail .list_detail_info dd .icon_arrow_right {
  float: right;
  margin-left: 6px;
}
.page_detail .list_detail_info dd .inner_dd {
  display: block;
  overflow: hidden;
  color: #111;
  text-align: right;
  word-break: break-all;
}

.page_detail .list_detail_info dd .inner_dd.primary {
  color: var(--primary-purple-300, #8f69dd);
}

.page_detail .list_detail_info dd .txt_placeholder {
  min-height: 24px;
  color: #999;
}

.page_detail .list_detail_info dd.dd_image {
  position: relative;
  overflow: visible;
  margin-top: 16px;
  padding: 100% 0 0 0;
  border-radius: 12px;
  background-color: #f3f1f1;
  text-align: center;
}
.page_detail .list_detail_info dd.dd_image .desc_placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: #999;
}
.page_detail .list_detail_info dd.dd_image.dd_has_image {
  margin-bottom: 11px;
  padding-top: 0;
}
.page_detail .list_detail_info dd.dd_image.dd_has_image .area_image {
  padding-top: calc(100% - 8px);
  border-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.page_detail .list_detail_info dd.dd_image.dd_has_image .area_feedbak_category {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3.44%;
  padding: 0 3.85% 0 7.694%;
}
.page_detail
  .list_detail_info
  dd.dd_image.dd_has_image
  .area_feedbak_category
  .img_text,
.page_detail
  .list_detail_info
  dd.dd_image.dd_has_image
  .area_feedbak_category
  .img_character {
  background-repeat: no-repeat;
  background-size: contain;
}
.page_detail
  .list_detail_info
  dd.dd_image.dd_has_image
  .area_feedbak_category
  .img_text {
  float: left;
  width: 55.074%;
  margin-top: 10.87%;
  padding-top: 12.32%;
}
.page_detail
  .list_detail_info
  dd.dd_image.dd_has_image
  .area_feedbak_category
  .img_character {
  float: right;
  width: 34.06%;
  padding-top: 34.06%;
}

.page_detail .btn_barcode .icon_exclam{display:inline-block;margin:2px 6px 2px 0;vertical-align:top}
.page_detail .btn_barcode .txt_value_barcode{display:inline-block;font-size:16px;font-weight:500;line-height:24px;vertical-align:top;text-decoration-line:underline;color:#111}

.page_detail .btn_primary {
  position: absolute;
  bottom: 32px;
  left: 20px;
  width: calc(100% - 40px);
}

/* 해결 방법 몰라서, 이렇게. */
.page_detail >>> .head_drop {
  padding-bottom: 32px;
}
</style>
