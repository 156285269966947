const InterFaceMixin = {
  data() {
    return {
      functionData: {
        webEnd: () => {
          window.FirFinInterface.webEnd();
        },
        pageMove: data => {
          window.FirFinInterface.pageMove(data);
        },
        webError: data => {
          window.FirFinInterface.webError(data);
        },
        moneyCharge: data => {
          window.FirFinInterface.moneyCharge(data);
        },
        barcodeShow: data => {
          window.FirFinInterface.barcodeShow(data);
        },
        payLogEditor: data => {
          window.FirFinInterface.payLogEditor(data);
        },
      },
    };
  },
  methods: {
    sendInterface(name, dataObj) {
      let data = {};
      if (dataObj) {
        data = Object.fromEntries(
          Object.entries(dataObj).filter(([_, v]) => v != ''),
        );
      }
      //안드로이드
      if (this.$varUA === 'ANDROID') {
        if (data) {
          let values = Object.values(data);
          if (values.length === 1) {
            values = values[0];
          }
          this.functionData[name](values);
        } else {
          this.functionData[name]();
        }
      }
      //IOS
      else if (this.$varUA === 'IOS') {
        let msg = Object.assign({}, data);
        msg.name = name;
        window.webkit.messageHandlers.FirFinInterface.postMessage(msg);
      }
      return;
    },
    customSendInterface(andInterfaceFunction, iosInterfaceFunction) {
      //IOS
      if (this.$varUA === 'IOS') {
        iosInterfaceFunction();
      }
      //안드로이드
      else {
        andInterfaceFunction();
      }
      return;
    },
    webEnd() {
      //안드로이드
      if (this.$varUA === 'ANDROID') {
        window.FirFinInterface.webEnd();
      }
      //IOS
      else if (this.$varUA === 'IOS') {
        window.webkit.messageHandlers.FirFinInterface.postMessage('webEnd');
      }
      return;
    },
    pageMove(pageName, addData) {
      let dataObj = {};
      if (addData) {
        dataObj = Object.assign({}, addData);
      }
      dataObj.pageName = pageName;
      this.sendInterface('pageMove', dataObj);
      return;
    },
  },
};
export default InterFaceMixin;
